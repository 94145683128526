import { Variants } from "framer-motion";

export const isIOS = (() => {
  var iosQuirkPresent = function () {
    var audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var isAppleDevice = navigator.userAgent.includes("Macintosh");
  var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

export const fadeInOut: Variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      // ease: [0.6, 0.01, -0.05, 0.95],
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

export const fadeIn: Variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      // ease: [0.6, 0.01, -0.05, 0.95],
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  // exit: {
  //   opacity: 0,
  //   y: 50,
  //   transition: {
  //     ease: "easeInOut",
  //     duration: 0.5,
  //   },
  // },
};

export const slideInOutVariant: Variants = {
  initial: {
    y: -100,
  },
  animate: {
    y: 0,
    scale: 1,
    transition: {
      // ease: [0.6, 0.01, -0.05, 0.95],
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

export const fadeInOutVariant: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      // ease: [0.6, 0.01, -0.05, 0.95],
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};
