import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
// import { isMobile } from "../../../constants";

const GameInfo = () => {
    return (
        <Box
        className="relative flex flex-col justify-top items-center h-auto w-full mb-20"
        >
            <Box className="flex flex-row justify-center w-full mt-10 md:mt-16 mb-3 px-5">
                <div className="w-60 border-b border-t md:border-t-0 border-white p-3 md:p-5">
                    <div className="text-[#04ced6] uppercase font-medium pb-2 tracking-widest text-sm">players</div>
                    <div className="text-lg font-semibold uppercase tracking-widest">2-8</div>
                </div>
                <div className="w-60 border-b border-t md:border-t-0 border-l md:border-x border-white p-3 md:p-5">
                    <div className="text-[#04ced6] uppercase font-medium pb-2 tracking-widest text-sm">time</div>
                    <div className="text-lg font-semibold uppercase tracking-widest">60 min</div>
                </div>
                <div className="w-60 border-b border-white p-5 hidden md:block">
                    <div className="text-[#04ced6] uppercase font-medium pb-2 tracking-widest text-sm">age restriction</div>
                    <div className="text-lg font-semibold uppercase tracking-widest">18+</div>
                </div>
            </Box>
            <Box className="flex flex-row justify-center w-full px-5 block md:hidden">
                <div className="w-full border-b border-white p-3 md:p-5">
                    <div className="text-[#04ced6] uppercase font-medium pb-2 tracking-widest text-sm">age restriction</div>
                    <div className="text-lg font-semibold uppercase tracking-widest">18+</div>
                </div>
            </Box>
            <Box className="text-[#04ced6] text-md md:text-xl 3xl:text-2xl leading-[2em] tracking-[.32em] text-center m-10 md:m-16 font-light">
                YOU HAVE 60 MINUTES TO ESCAPE.<br/>
                {/* OR IT’S YOUR LIFE.<br/> */}
                NO PRESSURE.
            </Box>
            <Box className="w-5/6 md:w-3/5 text-md md:text-xl 3xl:text-2xl text-center tracking-wide font-light leading-[1.5em] md:mb-8">
                Sign up below for a chance to participate in PinkPantheress' Escape Room.
                {/* You and your group of friends have been sentenced to life without possibility of parole. You are rotting in a maximum security prison. The catch? You didn't do it. While waiting for someone to help you, you plead for your life in court, but your appeals have been denied again and again. Your entire group was set up, and the only proof that will set you free, is outside of the walls of the prison. Since the criminal justice system is broken, the only thing you can do now is rely on those inside to help you escape. It seems everyone in the prison - the cook, the janitor, all of the trustees - all believe you, and will work together to make sure you don't rot in prison for the crime you didn't commit. But will their help be enough? */}
            </Box>
            {/* <Box className="w-5/6 md:w-3/5 text-sm md:text-lg text-center tracking-wide font-light leading-[1.5em]">
                With every single booking, we donate a portion of the proceeds to <a href="https://www.innocenceproject.org" className="text-[#04ced6]" target="_blank">innocenceproject.org</a>.<br/>
                Join a movement of 800,000+ supporters on a mission toward criminal justice reform.<br/>
                Your contribution helps us exonerate wrongfully convicted individuals.
            </Box> */}
        </Box>
    );
};

export default GameInfo;
