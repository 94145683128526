// 213x320

export const images = [
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
    "/photos/sample.png",
];