import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import COVER from "../../../assets/images/PPescape room hero desktop.png";
import COVER2 from "../../../assets/images/PPescape room hero mobile.png";
import LOGO from "../../../assets/images/pinkpantheress_logo-white.png";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/StyledComponent/InputField";
import SubscriptionController, {
  error,
} from "../../../api/Controller/SubcriptionController";
import {
  GeneralError,
  SubscriptionSubmitted,
  Success,
} from "../../../api/Controller/GSheetAPIController/Responds";
import Button from "../../../components/Form/Button";
import useSpreadSheet from "../../Hooks/useSpreadSheet";
// import { isMobile } from "../../../constants";

const BottomBorder = styled.div`
  background-color: #c3fb3e;
  box-shadow: 0 0 40px 5px #c3fb3e;
`;

const BGText = styled.div`
  font-size: 35px;
  line-height: 1.25em;
  color: #4436ff;
  opacity: 0.3;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 65px;
    opacity: 0.6;
    line-height: 1em;
    filter: blur(3px);
    letter-spacing: 0.22em;
  }

  @media (min-width: 1566px) {
    font-size: 95px;
    opacity: 0.3;
    filter: blur(5px);
    letter-spacing: 0.2em;
  }
`;

const Showcase = () => {
  return (
    <Box
      id="hero"
      className="relative flex flex-col justify-center items-center h-auto md:h-screen w-full"
    >
      <Box className="flex flex-col justify-top items-center h-full w-full">
        <img src={COVER} className="object-cover w-full h-72 md:h-full hidden md:block" />
        <img src={COVER2} className="object-cover w-full h-[90vh] md:h-full block md:hidden" />
        {/* <img src={LOGO} className="z-10 absolute top-5 md:top-12 left-5 md:left-20 w-24 md:w-64" /> */}
        {/* <Box className="absolute bottom-2 md:text-5xl 2xl:text-6xl font-semibold hidden md:block uppercase">PinkPantheress: Escape Room</Box> */}
        <BottomBorder className="fixed bottom-0 left-0 w-full h-[3px] z-12"></BottomBorder>
        {/* <BGText className="fixed -z-10 w-full text-center top-[17vh]">PRISON BREAK: WRONGFULLY<br/>CONVICTED</BGText> */}
      </Box>
      <Box className="text-4xl md:text-5xl 2xl:text-6xl text-center font-semibold mt-10 block md:hidden uppercase">PinkPantheress: Escape Room</Box>
    </Box>
  );
};

export default Showcase;
