import { Link as ScrollLink } from "react-scroll";
import { data } from "../Navigation/data";
import SocialIcons from "../SocialIcons/SocialIcons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Box, Container, Text } from "../StyledComponent";

export default function Footer() {
  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.to} // id of the section
        smooth
        duration={1000}
        spy={true}
        className="mr-2 text-gray-500 capitalize text-center md:mr-5 text-shadow cursor-pointer text-shadow text-lg"
      >
        {idx === 0 || idx === data.length ? null : (
          <span className="mr-3">|</span>
        )}
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        className="mr-2 md:mr-5 text-shadow text-shadow text-sm"
      >
        {item.name}
      </a>
    )
  );

  return (
    <>
      {/* <Container
        id="socials"
        className="w-full px-5 md:px-24 pt-4 md:pt-6 pb-2 md:pb-4 mx-auto text-center bg-black border-t"
      >
        <div className="text-md md:text-2xl text-[#04ced6] uppercase tracking-[.35em] -mr-[.35em] pb-3">Follow Us</div>
        <SocialIcons />
        <ScrollLink to={"hero"} smooth duration={1000} className="cursor-pointer hidden md:block">
          <div className="text-white text-xl absolute right-2 md:right-[10vw] -mt-[70px] border-2 border-[#04ced6] bg-[#3628e7] px-4 pt-2 pb-2 rounded-full"><FontAwesomeIcon icon={faArrowUp as any} /></div>
        </ScrollLink>
      </Container> */}
      <Container
        id="footer"
        className="w-full px-5 md:px-24 py-6 md:py-8 mx-auto text-center bg-black border-t"
      >
        {/* <Box className="text-white">{menuItems}</Box> */}
        <Text className="text-md md:text-xl text-white text-center block capitalize">
          &copy; {new Date().getFullYear()} PinkPantheress. All Rights Reserved.
        </Text>
        <ScrollLink to={"hero"} smooth duration={1000} className="cursor-pointer hidden md:block">
          <div className="text-white text-xl absolute right-2 md:right-[5vw] -mt-[40px] border-2 border-[#04ced6] bg-[#3628e7] px-4 pt-2 pb-2 rounded-full"><FontAwesomeIcon icon={faArrowUp as any} /></div>
        </ScrollLink>
        {/* <SocialIcons /> */}
      </Container>
    </>
  );
}
