import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import $ from "jquery";
import { isMobile } from "../../../constants";
import { isIOS } from "../../../util";

const Button = styled.div`
    :hover {
        text-shadow: 0 0 15px #ffffff, 0 0 15px #ffffff;
    }
`;

const slotList = {
    1: "1:00pm",
    2: "2:15pm",
    3: "3:30pm",
    4: "4:45pm",
    5: "",
    6: "6:00pm",
    7: "7:15pm",
    8: "8:30pm",
    9: "9:45pm",
    10: "",
    11: "11:00pm",
    12: "12:15am",
    13: "",
}

const notAvailable = ['slot_1', 'slot_2', 'slot_6'];

const ObjectRow = ({...props}) => {
    let cTime;
    switch (props.id.replace('slot_', '')) {
        case "12":
            cTime = props.id.replace('slot_', '') + ":00am";
            break;
        case "13":
            cTime = "1:00am";
            break;
        default:
            cTime = props.id.replace('slot_', '') + ":00pm";
    }

    function handleHoverIn(e: any) {
        if ($(e.target).hasClass("reserved")) return;

        if (! $(e.target).hasClass("disabled"))
            $(e.target).html("oh yeah");
    }

    function handleHoverOut(e: any) {
        if ($(e.target).hasClass("reserved")) return;

        if (! $(e.target).hasClass("selected-slot") && ! $(e.target).hasClass("disabled"))
            $(e.target).html("let's do it");
    }

    function handleClickSlot(e: any, id: any) {
        if ($(e.target).hasClass("reserved")) return;

        if (! $(e.target).hasClass("disabled")) {
            let available = 8 - parseInt($("#button-" + id).data('inmates'));
            // console.log(available);
            for (let i = 0; i < 8; i++) {
                if (i >= available) {
                    $("#count-" + (i+1)).addClass('hidden');
                } else {
                    if (available == 1) {
                        $("#count-1").show();
                    } else {
                        $("#count-" + (i+1)).removeClass('hidden');
                        $("#count-1").hide();
                    }
                }
            }
            $("#inmate-total").text($("#button-" + id).data('inmates'));

            $("#set-inmates").slideDown().delay(500).css('transform', 'translateX(0)');
            $("#submit-count").slideUp().css('transform', 'translateX(-100%)');
            $("#submit-info").slideUp().css('transform', 'translateX(-100%)');

            $(".selected-timeslot").text($("#buttonText-" + id).text());
            $("[id^='button-']:not(.disabled):not(.reserved)").removeClass("selected-slot").html("let's do it");
            $(".inmate-count").removeClass("selected-count");
            $(e.target).addClass("selected-slot").html("oh yeah");
            $(".timeslot-waitlist").hide();
        } else {
            $("#set-inmates").slideDown().css('transform', 'translateX(0)');
            $("#submit-count").slideUp().css('transform', 'translateX(-100%)');

            $(".selected-timeslot").text($("#buttonText-" + id).text());
            $("[id^='button-']:not(.disabled):not(.reserved)").removeClass("selected-slot").html("let's do it");
            $(".inmate-count").removeClass("selected-count");
            $(".timeslot-waitlist").show();
        }

        if (id > 11)
            $(".timeslot-day").text("11, saturday");
        else
            $(".timeslot-day").text("10, friday");
    }

    return (
        <Box
            className="flex flex-row text-md justify-center w-full"
            {...props}
        >
            <div id={"buttonText-" + props.id.replace('slot_', '')} className="flex py-2 pr-8 pl-4 md:px-8 justify-center items-center w-48">{slotList[props.id.replace('slot_', '')]}</div>
            <div className="flex-1 py-2 pr-8 pl-3 md:px-14">
                <ScrollLink key={props.key} to={(!notAvailable.includes(props.id) ? 'set-inmates' : '')} smooth duration={1000} delay={(isIOS ? 500 : 0)}>
                    <Button id={"button-" + props.id.replace('slot_', '')} className={`w-36 md:w-auto border border-2 border-[#3628e7] hover:border-[#04ced6] rounded-full text-[#04ced6] font-semibold cursor-pointer m-auto py-2 px-5 uppercase tracking-normal select-none whitespace-nowrap ${(notAvailable.includes(props.id) ? 'reserved' : '')}`} onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} onClick={(e) => handleClickSlot(e, props.id.replace('slot_', ''))} onTouchEnd={(e) => handleClickSlot(e, props.id.replace('slot_', ''))}>{(notAvailable.includes(props.id) ? "too late" : "let's do it")}</Button>
                </ScrollLink>
            </div>
        </Box>
    );
};

export default ObjectRow;
