import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import {
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ObjectRow from "./ObjectRow";
import IMG_TIME from "../../../assets/images/time.jpg";
import IMG_DRINKS from "../../../assets/images/drinks.jpg";
import IMG_PARKING from "../../../assets/images/parking.jpg";
import IMG_CHARITY from "../../../assets/images/charity.jpg";
import $ from "jquery";
import { isMobile } from "../../../constants";
import { isIOS } from "../../../util";

const TimeSlot = () => {
    const [inmateCount, setInmateCount] = useState<number>(0);
    const [timeSlot, setTimeSlot] = useState<string>("");
    const [waitlist, setWaitlist] = useState<number>(0);

    let timeSlots = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: -1,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: -1,
        11: 0,
        12: 0,
        13: -1,
    };

    useEffect(() => {
        fetch("https://sheets.googleapis.com/v4/spreadsheets/1EbpvIiJfOcGpnnx36J0VRVsDuITR_hRblcDuGvon-c0/values/Sheet1?key=AIzaSyCVk_tjjsHImNxo_VHJTnWmR23tG2kkzqQ")
        .then((res) => res.json())
        .then((json) => {
            $.each(json.values, function(idx, data) {
                if (idx == 0) {

                } else {
                    if (typeof data[1] !== 'undefined') {
                        let thisSlot = data[1].replace(/\s/g, '').toLowerCase();
                        let slot;
                        switch (thisSlot) {
                            case "1:30am":
                                slot = 13;
                                break;
                            default:
                                let getNumber = thisSlot.split(":");
                                slot = parseInt(getNumber[0]);
                        }
                        timeSlots[slot] = timeSlots[slot] + 1;
                    }
                }
            });

            // console.log(timeSlots);
            $.each(timeSlots, function(idx, val) {
                $("#button-" + idx).attr('data-inmates', val);
                if (val >= 8) {
                    $("#button-" + idx).addClass('disabled');
                    $("#button-" + idx).html("too late<br/>join waitlist")
                }
            });
        });
    }, []);

    function handleClick(e: any) {
        $("#submit-count").slideDown().css('transform', 'translateX(0)');
        $("#submit-info").slideUp().css('transform', 'translateX(-100%)');
        $(".inmate-count").removeClass("selected-count");
        $(e.target).addClass("selected-count");
        $(".inmate-number").html(e.target.innerHTML);
        setInmateCount(e.target.innerHTML);
        setTimeSlot($(".selected-timeslot:first").text());
        setWaitlist(($(".timeslot-waitlist:first").is(':visible') ? 1 : 0));
    }

    function handleHoverIn(e: any) {
        $(e.target).removeClass('flicker');
    }

    function handleHoverOut(e: any) {
        $(e.target).addClass('flicker');
    }

    function handleClickInfo(e: any) {
        $("#submit-info").slideDown().css('transform', 'translateX(0)');
    }

    function handleClickYes(e: any) {

    }

    function handleChange(e: any) {
        console.log(e.target.value)
        if (e.target.value !== "") {
            $(e.target).addClass('has-value');
        } else {
            $(e.target).removeClass('has-value');
        }
    }

    function handleFocus(e: any) {
        e.target.type = "date";
    }

    function handleBlur(e: any) {
        e.target.type = "text";
    }

    return (
        <>
            <Box
                className="relative flex flex-col justify-top items-center h-auto md:min-h-screen w-full mb-16 md:mb-24"
            >
                <span id="inmate-total" className="hidden">0</span>
                <Box className="text-lg md:text-4xl leading-[1.5em] tracking-[.32em] text-center font-bold">
                    EXPERIENCE
                </Box>
                <Box className="w-5/6 md:w-3/5 text-xs md:text-2xl text-center text-[#c3fb3e] tracking-[.32em] font-normal leading-[1.5em] mb-16 uppercase">
                    PinkPantheress: Escape Room
                </Box>
                <Box className="w-3/5 text-lg text-center tracking-wide font-light leading-[1.5em]">
                    <div className="flex border border-white rounded-full w-10 md:w-14 h-10 md:h-14 m-auto">
                        <div className="flex justify-center items-center border border-white rounded-full w-8 md:w-10 h-8 md:h-10 m-auto text-[#c3fb3e] text-md md:text-2xl select-none">1.</div>
                    </div>
                    <div className="uppercase mt-6 tracking-[.2em] text-sm md:text-md">
                        select a time slot
                    </div>
                </Box>
                <Box className="w-full md:w-1/3 3xl:w-1/4 text-lg text-center tracking-wide md:tracking-[.3em] font-normal leading-[1.5em] mt-10 px-5 md:px-0">
                    <Box className="flex flex-row uppercase text-sm md:text-md justify-center mb-4 md:mb-8">
                        <div className="border-b md:border border-white py-2 px-8 text-[#04ced6] text-center w-36 md:w-48 leading-[3em] md:leading-[1.5em]">time</div>
                        <div className="flex-1 border-b md:border border-white py-2 px-8 text-[#04ced6] font-bold md:font-normal"><FontAwesomeIcon icon={faCalendarAlt as any} className="text-[#c3fb3e] md:hidden" /> friday<span className="hidden md:inline">, Nov 10</span><span className="block md:hidden">11/10/2023</span> <FontAwesomeIcon icon={faCalendarAlt as any} className="text-[#c3fb3e] hidden md:inline" /></div>
                    </Box>
                    {(() => {
                        const slots = [];
                        // slots.push(<ObjectRow key={0} id={"slot_1"} />);
                        for (let i = 0; i < 13; i++) {
                            if (timeSlots[i+1] > -1)
                                slots.push(<ObjectRow key={i} id={"slot_" + (i+1)} />);
                        }
                        return slots;
                    })()}
                </Box>
            </Box>
            <Box
                id="set-inmates"
                className="hidden-container relative flex flex-col justify-top items-center w-full pt-5 mb-16 md:mb-24"
            >
                <Box className="text-lg md:text-4xl leading-[1.5em] tracking-[.32em] text-center font-bold">
                    EXPERIENCE
                </Box>
                <Box className="w-full md:w-3/5 text-xs md:text-2xl text-center text-[#c3fb3e] tracking-[.2em] md:tracking-[.32em] font-normal leading-[1.5em] mb-16 uppercase m-auto">
                    PinkPantheress: Escape Room<br/>
                    november <span className="timeslot-day">10, friday</span>, <span className="selected-timeslot">1:00pm</span><br/>
                    <span className="timeslot-waitlist">waitlist</span>
                </Box>
                <Box className="w-full md:w-3/5 text-lg text-center tracking-wide font-light leading-[1.5em] m-auto">
                    <div className="flex border border-white rounded-full w-10 md:w-14 h-10 md:h-14 m-auto">
                        <div className="flex justify-center items-center border border-white rounded-full w-8 md:w-10 h-8 md:h-10 m-auto text-[#c3fb3e] text-md md:text-2xl select-none">2.</div>
                    </div>
                    <div className="uppercase mt-6 tracking-[.2em] text-sm md:text-md">
                        select number of participants<br/><span className="timeslot-waitlist">on waitlist</span>
                    </div>
                </Box>
                <Box className="flex flex-row mt-8 md:mt-16 justify-center">
                    {(() => {
                        const arr = [];
                        for (let i = 0; i < 8; i++) {
                            // if (i == 0) continue;
                            arr.push(
                                <ScrollLink key={i} to="submit-count" smooth duration={1000} delay={(isIOS ? 500 : 0)}>
                                    <div id={`count-${i+1}`} className="inmate-count text-md md:text-2xl px-3 md:px-6 py-2 md:py-3 border border-white flex-1 mx-2 md:mx-3 font-semibold cursor-pointer hover:text-black hover:border-[#04ced6] hover:bg-[#04ced6] select-none" onClick={handleClick} onTouchEnd={handleClick}>{i+1}</div>
                                </ScrollLink>
                            );
                        }
                        return arr;
                    })()}
                </Box>
            </Box>
            <Box
                id="submit-count"
                className="hidden-container relative flex flex-col justify-top items-center w-full pt-5 mb-6 md:mb-8"
            >
                <Box className="text-lg md:text-4xl leading-[1.5em] tracking-[.32em] text-center font-bold">
                    EXPERIENCE
                </Box>
                <Box className="w-full md:w-3/5 text-xs md:text-2xl text-center text-[#c3fb3e] tracking-[.2em] md:tracking-[.32em] font-normal leading-[1.5em] mb-16 uppercase m-auto">
                    PinkPantheress: Escape Room<br/>
                    november <span className="timeslot-day">10, friday</span>, <span className="selected-timeslot">1:00pm</span><br/>
                    <span className="timeslot-waitlist">waitlist</span> <span className="inmate-number">{inmateCount}</span> people
                </Box>
                <Box className="w-full md:w-3/5 text-xs md:text-2xl text-center text-[#c3fb3e] tracking-[.32em] font-normal leading-[1.5em] mb-8 md:mb-16 uppercase m-auto">
                    {/* what's included?
                    <Box className="flex flex-row justify-center mt-0">
                        <div className="m-8 3xl:m-14 mt-10 text-center text-md md:text-lg">
                            <img src={IMG_TIME} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                60 mins<br/>
                                of fun
                            </div>
                        </div>
                        <div className="m-8 3xl:m-14 mt-10 text-center text-md md:text-lg">
                            <img src={IMG_DRINKS} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                free<br/>
                                sodas
                            </div>
                        </div>
                        <div className="m-8 3xl:m-14 mt-10 text-center text-md md:text-lg hidden md:block">
                            <img src={IMG_PARKING} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                free<br/>
                                parking
                            </div>
                        </div>
                        <div className="m-8 3xl:m-14 mt-10 text-center text-md md:text-lg hidden md:block">
                            <img src={IMG_CHARITY} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                charity<br/>
                                donations
                            </div>
                        </div>
                    </Box>
                    <Box className="flex flex-row justify-center mt-0 block md:hidden">
                        <div className="m-8 3xl:m-14 mt-4 text-center text-md md:text-lg">
                            <img src={IMG_PARKING} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                free<br/>
                                parking
                            </div>
                        </div>
                        <div className="m-8 3xl:m-14 mt-4 text-center text-md md:text-lg">
                            <img src={IMG_CHARITY} className="w-[7rem] md:w-24 3xl:w-32 h-[7rem]md:h-24 3xl:h-32 rounded-3xl" />
                            <div className="tracking-normal text-[#04ced6] leading-tight mt-4">
                                charity<br/>
                                donations
                            </div>
                        </div>
                    </Box> */}
                    <ScrollLink to="submit-info" smooth duration={1000} delay={(isIOS ? 500 : 0)}>
                        <div className="flicker button select-none" onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} onClick={handleClickInfo} onTouchEnd={handleClickInfo}>i'm in!</div>
                    </ScrollLink>
                </Box>
            </Box>
            <Box
                id="submit-info"
                className="hidden-container relative flex flex-col justify-top items-center w-full pt-5 mb-16 md:mb-24"
            >
                <Box className="text-lg md:text-4xl leading-[1.5em] tracking-[.32em] text-center font-bold">
                    EXPERIENCE
                </Box>
                <Box className="w-full md:w-3/5 text-xs md:text-2xl text-center text-[#c3fb3e] tracking-[.2em] md:tracking-[.32em] font-normal leading-[1.5em] mb-16 uppercase m-auto">
                    PinkPantheress: Escape Room<br/>
                    november <span className="timeslot-day">10, friday</span>, <span className="selected-timeslot">1:00pm</span><br/>
                    <span className="timeslot-waitlist">waitlist</span> <span className="inmate-number">{inmateCount}</span> people
                </Box>
                <Box className="w-full md:w-3/5 text-lg text-center tracking-wide font-light leading-[1.5em] m-auto">
                    <div className="flex border border-white rounded-full w-10 md:w-14 h-10 md:h-14 m-auto">
                        <div className="flex justify-center items-center border border-white rounded-full w-8 md:w-10 h-8 md:h-10 m-auto text-[#c3fb3e] text-md md:text-2xl select-none">3.</div>
                    </div>
                    <div className="uppercase mt-6 tracking-[.2em] text-sm md:text-md">
                        participants details
                    </div>
                </Box>
                <Box className="w-full md:w-5/6 3xl:w-3/5 text-lg text-center tracking-wide font-light leading-[1.5em] m-auto">
                    <form id="form-submit">
                        <input type="hidden" name="inmates" value={inmateCount} />
                        <input type="hidden" name="slot" value={timeSlot} />
                        <input type="hidden" name="waitlist" value={waitlist} />
                        {(() => {
                            const arr = [];
                            for (let i = 0; i < inmateCount; i++) {
                                arr.push(
                                    <div className="flex flex-col md:flex-row justify-center my-5 mx-10 md:mx-0 border-b md:border-b-0 border-white pb-5 md:pb-0">
                                        <div className="hidden md:flex flex-row justify-center items-center w-3">{i+1}.</div>
                                        <input name={`first_${i+1}`} type="text" placeholder="First name" autoComplete="off" className="mx-2 my-1 md:my-0 px-2 py-1 border border-white bg-transparent rounded-lg placeholder:text-[#3628e7] placeholder:text-md text-[#c3fb3e] text-md md:text-lg" required />
                                        <input name={`last_${i+1}`} type="text" placeholder="Last name" autoComplete="off" className="mx-2 my-1 md:my-0 px-2 py-1 border border-white bg-transparent rounded-lg placeholder:text-[#3628e7] placeholder:text-md text-[#c3fb3e] text-md md:text-lg" required />
                                        <input name={`email_${i+1}`} type="email" placeholder="Email" autoComplete="off" className="mx-2 my-1 md:my-0 px-2 py-1 border border-white bg-transparent rounded-lg placeholder:text-[#3628e7] placeholder:text-md text-[#c3fb3e] text-md md:text-lg" required />
                                        <input name={`ighandle_${i+1}`} type="text" placeholder="Instagram" autoComplete="off" className="mx-2 my-1 md:my-0 px-2 py-1 border border-white bg-transparent rounded-lg placeholder:text-[#3628e7] placeholder:text-md text-[#c3fb3e] text-md md:text-lg" required />
                                        <input name={`dob_${i+1}`} type="text" placeholder="Date of birth " autoComplete="off" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} onTouchEnd={handleFocus} className="h-[30px] md:h-auto w-auto mx-2 my-1 md:my-0 px-2 py-1 border border-white bg-transparent rounded-lg placeholder:text-[#3628e7] placeholder:text-md text-[#c3fb3e] text-md md:text-lg md:w-full" required />
                                    </div>
                                );
                            }
                            return arr;
                        })()}
                        <button type="submit" className="submit-info flicker button select-none" onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} onClick={handleClickYes} onTouchEnd={handleClickYes}>yessss</button>
                    </form>
                </Box>
            </Box>
        </>
    );
};

export default TimeSlot;
