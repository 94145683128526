import { RoutePattern } from "../../routes/RoutePattern";
import { motion } from "framer-motion";
import { fadeInOut, fadeIn } from "../../util";
import { useState, useEffect } from "react";
import useGlobalService from "../Hooks/useGlobalService";
import { useGlobalSelector } from "../../features/useSelector";
import "yet-another-react-lightbox/styles.css";
import {  images as IMAGES } from "./images";
import { isMobile } from "../../constants";
import { useLocation, useNavigate } from 'react-router-dom'

import BANNER_DESKTOP from "../../assets/images/Escape Rm Photos banner desktop.png";
import BANNER_MOBILE from "../../assets/images/Escape Rm Photos banner mobile.png";

const ImageGallery = () => {
  const { isLoaded } = useGlobalSelector();
  const [imgGroup, setImgGroup] = useState(0);
  const [imgSlides, setImgSlides] = useState(0);
  const [index, setIndex] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();

  let timeSlots = {
      1: "01:00 pm",
      2: "02:15 pm",
      3: "03:30 pm",
      4: "04:45 pm",
      5: -1,
      6: "06:00 pm",
      7: "07:15 pm",
      8: "08:30 pm",
      9: "09:45 pm",
      10: -1,
      11: "11:00 pm",
      12: "12:15 am",
      13: -1,
  };

  function handleClickImg(img: any) {
    window.open(img, "_blank", "noreferrer");
  }

  return (
    <>
        <div id="hero" className="w-full cursor-pointer">
            <img src={isMobile ? BANNER_MOBILE : BANNER_DESKTOP} onClick={() => navigate("/")} />
        </div>
        <motion.div
            variants={fadeIn}
            initial="initial"
            // animate={isLoaded ? "animate" : "initial"}
            animate="animate"
            exit="exit"
            className="w-full lg:w-3/4 m-auto my-16 md:my-24"
        >
            <div className="flex flex-row justify-center flex-wrap">
                {(() => {
                    const slots = [];
                    for (let i = 0; i < 10; i++) {
                        // if (timeSlots[i+1] != -1)
                        slots.push(<div className="cursor-pointer flex justify-center text-white border border-white m-2 md:m-5" onClick={() => handleClickImg(IMAGES[i])}><img src={IMAGES[i]} className="w-72" /></div>);
                    }
                    return slots;
                })()}
            </div>
        </motion.div>
    </>
  );
};

export default ImageGallery;
